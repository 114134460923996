<template>
  <v-card class="pa-2" :loading="saving" :disabled="saving">
    <v-card-text>
      <v-autocomplete
        v-model="new_ca_rule_name"
        :items="issued_by_entries"
        :loading="issued_by_loading"
        :search-input.sync="issued_by_search"
        item-text="friendly_name"
        hide-no-data
        item-value="sha1"
        placeholder="CA Name"
        class="custom-placeholder"
        prepend-icon="mdi-database-search"
        hint="Type to search"
        clearable
        return-object
      ></v-autocomplete>

      <template v-if="public_keys.length > 0">
        <v-row align="center" v-for="key in public_keys" :key="key.id">
          <v-checkbox
            v-model="new_ca_rule_keys"
            :value="key.id"
            class="shrink mt-0 ml-5"
            v-bind:key="key.id"
          >
            <template v-slot:label>
              <strong>{{
                key.certificates_with_public_key[0].issuer_fqdn.friendly_name
              }}</strong>
              &nbsp;
              {{
                key.certificates_with_public_key[0].valid_from
                  | moment("MMM D YYYY")
              }}
              <br />
              <i>{{ key.algorithm.name }}-{{ key.bit_size }}</i>

              <v-dialog width="800" class="pa-0">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    color="primary"
                    v-bind="attrs"
                    @click="
                      certs_loading = true;
                      get_name_certs(key.id);
                    "
                    v-on="on"
                    x-small
                    class="mt-0"
                    v-if="key.certificates_with_public_key.length !== 1"
                  >
                    <v-icon small>mdi-eye</v-icon>
                    {{ key.certificates_with_public_key.length }} Issuers
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    v-bind="attrs"
                    @click="
                      certs_loading = true;
                      get_name_certs(key.id);
                    "
                    v-on="on"
                    x-small
                    class="mt-0"
                    v-else
                  >
                    <v-icon small>mdi-eye</v-icon> 1 Issuer
                  </v-btn>
                </template>

                <v-card class="pa-0">
                  <v-card-text class="pa-0">
                    <v-data-table
                      :headers="cert_table_headers"
                      :items="certs"
                      class="elevation-1 pa-0"
                      :loading="certs_loading"
                      :sort-by="['valid_from']"
                      :sort-desc="true"
                    >
                      <template v-slot:top>
                        <v-toolbar flat>
                          <v-toolbar-title>{{
                            new_ca_rule_name.friendly_name
                          }}</v-toolbar-title>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <span v-if="!certs_loading" style="font-size:.8em"
                            >Matches {{ num_certs }} certificates</span
                          >
                        </v-toolbar>
                      </template>
                      <template v-slot:item.id="{ item }">
                        <v-btn
                          :href="`/cert/${item.id}/`"
                          class="ma-0 pa-0"
                          outlined
                          color="primary"
                          style="height:40px;width:20px !important;float:left;border-radius: 3px 0px 0px 3px;"
                          dense
                        >
                          <v-icon color="primary" class="ma-0 pa-0 pl-0"
                            >mdi-certificate-outline</v-icon
                          >
                        </v-btn>

                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="primary"
                              dark
                              v-bind="attrs"
                              v-on="on"
                              x-small
                              class="mt-0"
                              style="float:right;height:40px;border-radius: 0 3px 3px 0;"
                              ><v-icon class="ma-0 pa-0" small
                                >mdi-menu-down</v-icon
                              >
                            </v-btn>
                          </template>
                          <v-list dense>
                            <v-list-item>
                              <v-list-item-title
                                >Add to Compare</v-list-item-title
                              >
                            </v-list-item>

                            <v-list-item
                              :href="`/compare/fixed/?certs=${item.id}`"
                            >
                              <v-list-item-title
                                >View on Compare</v-list-item-title
                              >
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </template>

                      <template
                        v-slot:item.issuer_fqdn.friendly_name="{ item }"
                      >
                        <span style="font-size:.8em;">{{
                          item.issuer_fqdn.friendly_name
                        }}</span>
                      </template>

                      <template
                        v-slot:item.subject_fqdn.friendly_name="{ item }"
                      >
                        <span style="font-size:.8em;">{{
                          item.subject_fqdn.friendly_name
                        }}</span>
                      </template>

                      <template v-slot:item.valid_from="{ item }">
                        {{ item.valid_to | date }}
                      </template>

                      <template v-slot:item.is_revoked="{ item }">
                        <v-icon
                          color="danger"
                          v-if="item.is_revoked"
                          style="float:left"
                          >mdi-close-circle</v-icon
                        >
                        <v-icon v-else color="success" style="float:left"
                          >mdi-check-circle</v-icon
                        >

                        <v-icon
                          v-if="item.is_hosted"
                          color="success"
                          style="float:left;margin-left:3px;"
                          >mdi-earth</v-icon
                        >
                        <v-icon
                          v-else
                          color="#666"
                          style="float:left;margin-left:3px;"
                          >mdi-earth</v-icon
                        >
                      </template>

                      <template v-slot:loading>
                        <svg
                          viewBox="-2 -5 90 46"
                          width="100"
                          height="50"
                          style="margin-top:15px;"
                        >
                          <path
                            class="path3"
                            fill="#e4e4e4"
                            stroke="rgba(5, 55, 104,.6)"
                            stroke-width="2"
                            d="M 33.6 8.8 L 22.35 8.8 L 22.35 34.4 L 11.3 34.4 L 11.3 8.8 L 0 8.8 L 0 0 L 33.6 0 L 33.6 8.8 Z"
                          />
                          <path
                            class="path3"
                            fill="#e4e4e4"
                            stroke="rgba(113, 146, 176,.6)"
                            stroke-width="2"
                            d="M 79.15 34.4 L 68.2 34.4 L 68.2 22 Q 68.2 19.7 68.375 17.275 A 121.746 121.746 0 0 1 68.51 15.565 Q 68.567 14.898 68.627 14.307 A 57.598 57.598 0 0 1 68.75 13.2 Q 68.944 11.6 68.997 11.128 A 27.416 27.416 0 0 0 69 11.1 L 68.8 11.1 L 62.45 34.4 L 53.8 34.4 L 47.4 11.15 L 47.2 11.15 A 17.772 17.772 0 0 0 47.225 11.36 Q 47.292 11.905 47.475 13.225 A 47.654 47.654 0 0 1 47.586 14.087 Q 47.747 15.435 47.895 17.217 A 129.35 129.35 0 0 1 47.9 17.275 A 57.649 57.649 0 0 1 48.1 21.856 A 53.066 53.066 0 0 1 48.1 22 L 48.1 34.4 L 37.95 34.4 L 37.95 0 L 53.55 0 L 58.75 19.85 L 58.95 19.85 L 64.1 0 L 79.15 0 L 79.15 34.4 Z"
                          />
                        </svg>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </template>
          </v-checkbox>
        </v-row>
      </template>
      <template v-else>
        <v-card :loading="keys_loading" v-if="keys_loading"
          >Loading Keys...</v-card
        >
      </template>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="close_dialog" color="gray">Cancel</v-btn>
      <v-btn
        @click="save_rule"
        color="success"
        :disabled="new_ca_rule_keys.length === 0"
        >Add Keys</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import UserService from "../../../store/UserService";

export default {
  name: "NewCaRule",
  props: ["group"],
  data: function() {
    return {
      dialog: false,
      saving: false,
      saved: false,
      error: false,
      editing: false,
      unsaved: null,
      issued_by_loading: false,
      issued_by_entries: [],
      new_ca_rule_name: null,
      issued_by_search: null,
      issued_by_count: null,
      new_ca_rule_keys: [],
      keys_loading: false,
      public_keys: [],
      certs_loading: false,
      num_certs: null,
      certs: [],
      cert_table_headers: [
        { text: "", sortable: false, width: "104", value: "id" },
        { text: "Issuer", sortable: true, value: "issuer_fqdn.friendly_name" },
        /*{text: 'Subject', sortable: true, value: 'subject_fqdn.friendly_name'},*/
        { text: "Valid To", sortable: true, width: 120, value: "valid_from" },
        { text: "Status", sortable: true, width: 120, value: "is_revoked" }
      ]
    };
  },
  methods: {
    close_dialog: function() {
      this.$emit("close-dialog");
    },
    save_rule: function() {
      let self = this;
      self.saving = true;
      for (let new_key in this.new_ca_rule_keys) {
        UserService.postLocalApi("monitoring-group-ca-rules/", {
          monitoring_group: this.group.group.id,
          public_key_id: this.new_ca_rule_keys[new_key],
          fqdn_id: this.new_ca_rule_name.id
        })
          .then(response => {
            self.$emit("add-ca-rule", self.group, response.data);
            self.new_ca_rule_keys = [];
            self.new_ca_rule_name = null;
            self.saving = false;
            self.editing = false;
          })
          .catch(function(error) {
            console.log(error);
            self.saving = false;
          });
      }
    },
    get_name_certs: function(key_id) {
      this.certs_loading = true;
      this.certs = [];
      let self = this;
      let args = [
        "groups=true",
        "size=10000",
        "ca=true",
        "subject_sha1=" + self.new_ca_rule_name.sha1,
        "public_key_id=" + key_id
      ];

      UserService.getLocalApi("certificates/?" + args.join("&")).then(
        response => {
          self.certs = response.data.results;
          self.certs_loading = false;
          self.num_certs = response.data.count;
        }
      );
    }
  },
  watch: {
    new_ca_rule_name(val) {
      this.public_keys = [];
      this.new_ca_rule_keys = [];
      if (val) {
        this.keys_loading = true;
        UserService.getLocalApi(
          "public-keys/?groups=true&expired=false&subject_sha1=" + val.sha1
        )
          .then(response => {
            this.public_keys = response.data.results;
            this.new_ca_rule_keys = response.data.results.map(a => a.id);
            this.keys_loading = false;
          })
          .catch(err => {
            console.log(err);
          });
      }
    }
  },
  computed: {},
  created: function() {
    UserService.getLocalApi("fqdns/?size=10000")
      .then(response => {
        this.issued_by_count = response.data.count;
        this.issued_by_entries = response.data.results;
        this.issued_by_loading = false;
      })
      .catch(err => {
        console.log(err);
      });
  }
};
</script>

<style scoped></style>
