<template>
  <v-row class="pa-2">
    <v-col cols="12" class="ma-0">
      <v-alert type="error" text v-if="group_error">
        {{ group_error }}
      </v-alert>

      <v-dialog v-model="color_dialog" width="300">
        <v-card>
          <v-card-title v-if="color_group">
            [[ color_group.group.name ]] Color
          </v-card-title>
          <v-card-title v-else>New Group Color</v-card-title>

          <v-card-text class="ma-0 pa-0">
            <v-color-picker
              v-model="color_picked"
              class="ma-2 mb-0 mt-0"
              hide-inputs
            ></v-color-picker>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="color_dialog = false">
              Cancel
            </v-btn>
            <v-btn
              color="success"
              @click="
                color_group.settings.color = color_picked.hex;
                color_dialog = false;
              "
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="delete_dialog" width="500" v-if="group_to_delete">
        <v-card
          v-if="group_to_delete.group.group_type === 3"
          :loading="deleting"
        >
          <v-card-title class="headline" primary-title>
            Stop monitoring
            <span style="color:#7192B0;font-weight:bolder;" class="pl-2"
              >[[ group_to_delete.group.name ]]</span
            >?
          </v-card-title>

          <v-card-text>
            This will not delete the application but it will remove your
            monitoring of it.
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="group_to_delete = null">CANCEL</v-btn>
            <v-btn
              color="error darken-1"
              @click="remove_group_monitoring(group_to_delete)"
              >DELETE</v-btn
            >
          </v-card-actions>
        </v-card>
        <v-card v-else :loading="deleting">
          <v-card-title class="headline" primary-title>
            Delete Group
            <span style="color:#7192B0;font-weight:bolder;" class="pl-2"
              >[[ group_to_delete.group.name ]]</span
            >?
          </v-card-title>

          <v-card-text>
            This action cannot be undone and will delete all monitoring rules
            and settings.
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="group_to_delete = null">CANCEL</v-btn>
            <v-btn color="error darken-1" @click="delete_group(group_to_delete)"
              >DELETE</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="new_group_dialog" max-width="500px">
        <AddOrCreateGroup @close-dialog="new_group_dialog = false" />
      </v-dialog>
      <v-btn color="success" dark class="mb-2" @click="new_group_dialog = true"
        ><v-icon>mdi-plus</v-icon> Add Group</v-btn
      >
      <MonitoringGroupTile
        v-for="group in monitored_groups"
        :group="group"
        :key="group.id"
      />
    </v-col>
  </v-row>
</template>

<script>
import MonitoringGroupTile from "../components/MonitoringGroupTile";
import AddOrCreateGroup from "../components/groups/AddOrCreateGroup";
export default {
  name: "MonitoringGroups",
  components: { AddOrCreateGroup, MonitoringGroupTile },
  data: function() {
    return {
      expanded: false,
      group_error: null,
      new_group_dialog: false,
      group_to_delete: null,
      color_picked: null,
      color_group: null,
      color_dialog: false
    };
  },
  computed: {
    do_i_show: function() {
      return this.$vuetify.breakpoint.smAndUp || this.expanded;
    },
    monitored_groups: function() {
      return this.$store.getters.monitored_groups;
    }
  }
};
</script>

<style scoped></style>
