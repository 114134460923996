<template>
  <v-card class="pa-2">
    <v-card-title>
      Add or create a monitoring group
    </v-card-title>

    <v-card-text class="ma-0">
      <v-radio-group v-model="new_group_type" dense>
        <v-radio
          :key="1"
          :label="`CA: monitor certification authorities`"
          :value="1"
          dense
        ></v-radio>

        <v-card v-if="new_group_type === 1" class="ma-0 pa-1 ml-7 mb-2">
          <v-text-field
            label="Group Name"
            hide-details
            dense
            v-model="new_group_name"
            outlined
            required
          >
            <template v-slot:prepend class="pa-0 ma-0">
              <v-card
                :style="
                  `width:40px;height:40px;background-color:${new_group_color};`
                "
                elevation="4"
                color="item.color"
                class="pa-0 ma-0 mt-n2"
                @click.stop="
                  color_dialog = true;
                  color_group = null;
                "
              ></v-card>
            </template>
          </v-text-field>
        </v-card>

        <v-radio
          :key="2"
          :label="`Dynamic: monitor by trust anchors`"
          :value="2"
          dense
        ></v-radio>

        <v-card v-if="new_group_type === 2" class="ma-0 pa-1 ml-7 mb-2">
          <v-text-field
            label="Group Name"
            hide-details
            dense
            v-model="new_group_name"
            outlined
            required
          >
            <template v-slot:prepend class="pa-0 ma-0">
              <v-card
                :style="
                  `width:40px;height:40px;background-color:${new_group_color};`
                "
                elevation="4"
                color="item.color"
                class="pa-0 ma-0 mt-n2"
                @click.stop="
                  color_dialog = true;
                  color_group = null;
                "
              ></v-card>
            </template>
          </v-text-field>
        </v-card>

        <v-card v-if="new_group_type === 3" class="ma-1 pa-1 ml-7">
          <v-card-text>
            <v-select
              v-model="new_existing_group"
              :items="unmonitored_groups"
              label="Select an application"
              item-text="group.name"
              item-value="group.id"
              hint="Applications groups can be modified blah blah"
              persistent-hint
            >
              <template v-slot:prepend class="pa-0 ma-0">
                <v-card
                  :style="
                    `width:40px;height:40px;background-color:${new_group_color};`
                  "
                  elevation="4"
                  color="item.color"
                  class="pa-0 ma-0 mt-n2"
                  @click.stop="
                    color_dialog = true;
                    color_group = null;
                  "
                ></v-card>
              </template>
            </v-select>
          </v-card-text>
        </v-card>
      </v-radio-group>
    </v-card-text>
    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary darken-1" @click.stop="close_dialog" text
        >Cancel</v-btn
      >
      <v-btn
        color="success darken-1"
        @click="monitor_existing_group"
        v-if="new_group_type === 3"
        >Add to Monitoring</v-btn
      >
      <v-btn color="success darken-1" @click="create_new_group" v-else
        >Create Group</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import UserService from "../../store/UserService";

export default {
  name: "AddOrCreateGroup",
  data: function() {
    return {
      new_existing_group: null,
      new_group_type: 1,
      new_group_name: null,
      rules_loading: false,
      rule_types: ["name", "path"],
      new_rule_type: "name",
      descriptionLimit: 60,
      new_ca_rule_issued_to: null,
      new_ca_rule_issued_by: null,
      new_rule_cas: true,
      new_rule_ees: false,
      rule_error: null,
      friendly_colors: [
        "#bdcdd1",
        "#c6cda7",
        "#f3e8ca",
        "#baa598",
        "#f8dcb1",
        "#e5b8a3",
        "#77818c",
        "#8e9b45",
        "#b5ad8c",
        "#b38881"
      ]
    };
  },
  computed: {
    monitored_groups: function() {
      // all groups and their settings if they exist
      return this.$store.getters.monitored_groups;
    },
    unmonitored_groups: function() {
      return this.$store.getters.unmonitored_groups;
    },
    used_group_colors: function() {
      if (!this.group_settings_loading) {
        return this.monitored_groups.map(e => e.settings.color);
      } else {
        return [];
      }
    },
    new_group_color: function() {
      for (const x in this.friendly_colors) {
        if (!this.used_group_colors.includes(this.friendly_colors[x])) {
          return this.friendly_colors[x];
        }
      }
      return "#0D3E6D";
    }
  },
  methods: {
    close_dialog: function() {
      this.$emit("close-dialog");
    },
    create_new_group: function() {
      let self = this;
      this.create_dialog = false;
      //app.groups = [];
      self.group_error = null;

      UserService.postLocalApi("monitoring-groups", {
        name: this.new_group_name,
        color: this.new_group_color,
        group_type: this.new_group_type
      })
        .then(res => {
          console.log(res);
          self.ignore_changes = true;
          self.$emit("close-dialog");
          this.$store.dispatch("loadUserSettings", false);
        })
        .catch(function(error) {
          self.group_error = error;
        });
    }
  }
};
</script>

<style scoped></style>
